<template>
    <div class="section-shop--listing">

      <div class="" v-if="isLoading">
        <div class="" v-for="i in parseFloat(filters.limit)" :key="i">
          <Skelton />
        </div>
      </div>

      <div class="product-item" v-for="(product,index) in products" :key="index" v-else>
        <div class="ps-product--list">
          <div class="ps-product__left">           
              <router-link :to="{name: 'product-view', params: {productId: product.url_key}}">
                <img class="ps-product__thumbnail" :src="product.base_image.medium_image_url" alt="alt" />
              </router-link>
            
            <div class="ps-product__content">
              <a class="ps-product__name mb-4 mt-4" href="#">{{ product.name }}</a>
              <!-- <div class="ps-product__rating">
                <star-rating :star-size="14" :rounded-corners="true" :border-width="1" :show-rating="false"
                  :read-only="true" :rating="0"></star-rating>
                <span>({{ product.reviews.total_rating }})</span>
              </div> -->
              <div class="ps-product__des">
                <div class="def-number-input number-input safari_only">
                  <button class="minus" @click="decreaseQuantity(product.id)">
                    <i class="icon-minus"></i>
                  </button>
                  <input class="quantity" min="0" name="quantity" type="number" v-model="product.qty" />
                  <button class="plus" @click="increaseQuantity(product.id)">
                    <i class="icon-plus"></i>
                  </button>
                </div>
                <div class="price mt-4"><b>Price: </b><span class="price-sale">{{ product.formated_price }}</span><span class="price-off">{{ product.formated_regular_price }}</span></div>
              </div>
            </div>
          </div>
          <div class="ps-product__right">
            <div class="ps-product__price"></div>
            <div class="ps-product__unit"></div>
              <router-link
                class="btn add-to-cardd"
                v-if="product.variants"
                :to="{ name: 'product-view', params: { productId: product.id } }">
                Addons
              </router-link>
              <button class="btn add-to-cardd" @click="addToCart({product_id:product.id,quantity:product.qty})" v-else-if="!product.is_item_in_cart">
                <i class="icon-cart"></i> Add to cart
              </button>
            <div class="ps-product__box">
              <button class="btn add-to-wistlistt" @click="addToList({product_id:product.id,quantity:product.qty})">
              <i class="icon-heart"></i> Wishlist
            </button>
            </div>
          </div>
        </div>
      </div>
    </div>
 
</template>
<script>
  import {
    mapActions,
    mapGetters
  } from 'vuex'
  // import StarRating from 'vue-star-rating'
  import Skelton from "./SkeltonList.vue";
  export default {
    components: {
      Skelton,
      // StarRating
    },
    computed: {
      ...mapGetters('product', ['products', 'isLoading', 'filters']),
    },
    methods: {
      ...mapActions('cart', ['addToCart']),
      ...mapActions("wishlist", ["addToList"]),
      ...mapActions('product', ['increaseQuantity', 'decreaseQuantity'])
    },
  }
</script>
<style lang="scss">
  .product-item{
    background: #fff;
    margin-bottom: 10px;
    border:1px solid #ececec;
    img{
      width: 100%;
    }
    .ribbon {
      position: absolute;
      top: 1px;
      z-index: 1;
      overflow: hidden;
      width: 100px;
      height: 100px;
      text-align: right;
        span {

          font-size: 14px;
          font-weight: bold;
          color: #FFF;
          text-transform: uppercase;
          text-align: center;
          line-height: 20px;
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
          width: 130px;
          display: block;
          background: linear-gradient(#ff730077 0%, #FF7200 100%);
          position: absolute;
          top: 20px;
          left: -28px;
          padding: 2px 10px;
          box-shadow: 0 3px 10px -5px #000;
        }
    }
    .content{
      padding: 20px 10px;
      border-top:1px solid #ececec;
    }
    .title {
      text-align: left;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .price{
      text-align: left;
    }
    .price-sale {
      text-align: left;
    }
    .price-off{ 
      margin-left: 5px;
      text-decoration: line-through;
      font-size: 13px;
      color:#dc3545;
    }
    .action-button-sec{
      text-align: right;
      margin-top: 25px;
      font-size: 14px;
      a{
        display: inline-block;
        padding: 3px 6px;
      }
    }
    .add-to-wistlistt{
      color: #FF7200;
      border: none;
  width           : 100%;
  font-size       : 14px;
      
    }
    .add-to-cardd{
      border: 1px solid #FF7200;      
      color: #FF7200;
      border-radius   : 3px;
  width           : 100%;
  font-size       : 14px;
  padding         : 5px;
    }

    .add-to-cardd:hover{
        color: #fff;
        background:  #FF7200;
    }
    .add-to-wistlistt:hover{
        border:  1px solid #FF7200;
    }
  } 
</style>
