<template>
  <div class="product-item">
    <div class="ribbon" v-if="percentagePrice"><span>{{percentagePrice}}% OFF</span></div>
   <a href="#" @click.prevent="viewProduct(product)">
      <img class="ps-product__thumbnail" :src="product.base_image.medium_image_url" alt="alt" />
   </a>
    <div class="content">
        <div class="price">
          <span class="price-sale">{{ product.formated_price }}</span><span class="price-off">{{ product.formated_regular_price }}</span>
        </div>

        <a href="#" @click.prevent="viewProduct(product)">
          <h5 class="title">{{ product.name }}</h5>
        </a>

      <div class="action-button-sec" v-if="buttons">
        <div class="action-button">
          <a href="javascript:void(0)" class="add-to-wistlist" @click.prevent="addToList({ product_id: product.id })">
            <i class="icon-heart"></i>
          </a>
          
          <template v-if="product.in_stock">
            <router-link class="add-to-card" 
              v-if="isVariants"
              :to="{ name: 'product-view', params: { productId: product.id } }">
              Addons
            </router-link>
            <a class="add-to-card" href="javascript:void(0)" 
              v-else
              @click.prevent="addToCart({ product_id: product.id, quantity: product.qty })">
              <i class="icon-cart"></i> Add to cart
            </a>
          </template>
          <template v-else>
            <a class="btn-sold-out" href="javascript:void(0)">
              Not in stock
            </a>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: {
    product:{
      type:Object
    },
    buttons:{
      type:Boolean,
      default:true
    }
  },

  components: {},
  mounted() {},

  computed: {
    isVariants() {
      if(this.product.variants) {
        return !!this.product.variants.length
      }
      return false;
    },

    percentagePrice() {
      var balancePrice = (100 / this.product.regular_price) * (this.product.regular_price - this.product.special_price);
      if(balancePrice) {
        return Number((balancePrice).toFixed(2));
      }else {
        return 0;
      }
    }
  },

  methods: {
    ...mapActions("cart", ["addToCart"]),
    ...mapActions("product", ["increaseQuantity", "decreaseQuantity",'fetchProduct']),
    ...mapActions("wishlist", ["addToList"]),
    viewProduct(item) {
      this.showResult = false;
      this.$router.push({
        name: "product-view",
        params: { productId: item.id },
        force: true,
      });
      this.fetchProduct(item.id);
    },
  },
};
</script>

<style lang="scss">
  .product-item{
    background: #fff;
    margin-bottom: 10px;
    border:1px solid #ececec;
    box-shadow: 0px 4px 13px 1px #f1f1f1;
    border-radius: 10px;
    overflow: hidden;
    &:hover {
      box-shadow: -5px 2px 9px 0px #e1e1e1;
    }
    img{
      width: 100%;
      // height: 185px;
    }
    .ribbon {
      position: absolute;
      top: 1px;
      z-index: 1;
      overflow: hidden;
      width: 100px;
      height: 100px;
      text-align: right;
        span {

          font-size: 14px;
          font-weight: bold;
          color: #FFF;
          text-transform: uppercase;
          text-align: center;
          line-height: 20px;
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
          width: 130px;
          display: block;
          background: linear-gradient(#ff730077 0%, #FF7200 100%);
          position: absolute;
          top: 20px;
          left: -28px;
          padding: 2px 10px;
          box-shadow: 0 3px 10px -5px #000;
        }
    }
    .content{
      padding: 20px 10px;
      border-top:1px solid #ececec;
    }
    .title {
      text-align: left;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .price{
      text-align: left;
    }
    .price-sale {
      text-align: left;
    }
    .price-off{ 
      margin-left: 5px;
      text-decoration: line-through;
      font-size: 13px;
      color:#dc3545;
    }
    .action-button-sec{
      text-align: right;
      margin-top: 25px;
      font-size: 14px;
      a{
        display: inline-block;
        padding: 3px 6px;
      }
    }
    .add-to-wistlist{
      border: 1px solid #FF7200;
      color: #FF7200;
      margin-right: 5px;
      
    }
    .add-to-card{
      border: 1px solid #FF7200;      
      color: #FF7200;
      margin-right: 8px;
    }
    .add-to-card:hover,.add-to-wistlist:hover{
        color: #fff;
        background:  #FF7200;
    }
    .btn-sold-out {
      border: 1px solid var(--red);  
      background:  var(--red);    
      color: var(--white);
      margin-right: 8px;
      cursor: not-allowed;      
    }
    .btn-sold-out:hover {
        color: var(--red);
        background:  var(--white);
    }
  } 
</style>
