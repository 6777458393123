<template>
<div class="product-item">
      <div class="ps-product--list">
        <div class="ps-product__left">
          <div class="ps-product__extent">
            <Skeletor height="120" />
          </div>
          <div class="ps-product__content">
            <div class="ps-product__name">
              <Skeletor width="75%" />
            </div>
            <div class="ps-product__rating">
              <Skeletor width="40%" />
            </div>
          </div>
        </div>
      </div>
</div>

</template>
<script>
import "vue-skeletor/dist/vue-skeletor.css";
import { Skeletor } from "vue-skeletor";
export default {
  components:{Skeletor},
}
</script>