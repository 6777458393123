<template>
  <div class="section-shop--grid-s">
    <div class="row"  v-if="!isLoading || products.length" style="margin:0">
      <div
        class="col-6 col-md-3 cart-marg"
        v-for="(product,index) in products"
        :key="index"
      >
      <product-default :product="product"/>
      </div>
    </div>

    <div class="row" v-if="fetchingProducts || isLoading">
      <div class="col-6 col-md-2 cart-marg" v-for="i in parseFloat(filters.limit)" :key="i">
        <product-skelton/>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import "vue-skeletor/dist/vue-skeletor.css";
import ProductDefault from '../../Components/ProductDefault.vue';
import ProductSkelton from '../../Components/ProductSkelton.vue';
export default {
  components:{ ProductDefault, ProductSkelton},
  data() {
    return {
      qty:[]
    }
  },
  computed:{
    ...mapGetters('product',['products','isLoading','filters','fetchingProducts']),
    productTotal(){
      return this.products.map((product) => {
        return `$${parseFloat(product.price)*product.qty}`;
      });
    }
  },
  methods: {
    ...mapActions('cart',['addToCart']),
    ...mapActions('wishlist',['addToList']),
    ...mapActions('product',['increaseQuantity','decreaseQuantity'])
  },
  
}
</script>
<style lang="scss" scoped>
.ps-product--standard{
  border: 1px solid rgb(219, 219, 219);
  padding: 0px;
}
.content{
  padding: 10px;
}
.cart-marg{
  padding: 2px;
}
</style>