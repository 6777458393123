<template>
  <div class="row mt-3">
    <div class="col-12">
      <div class="result__header">
        <h4 class="title">{{ meta.total }}<span>Product Found</span></h4>
      </div>

      <div class="filter__mobile">
        <div class="viewtype--block">
          <div class="viewtype__sortby">
            <div class="select">
              <select class="single-select2-no-search" v-model="sort_by">
                <option value="" selected="selected">
                  Sort by
                </option>
                <option value="name-asc">Sort by Name (A-Z)</option>
                <option value="name-desc">Sort by Name (Z-A)</option>
                <option value="price-asc">Sort by Price - Low to High</option>
                <option value="price-desc">Sort by Price - High to Low</option>
              </select>
            </div>
          </div>
          <div class="viewtype__select d-none d-lg-block">
            <div class="type">
              <router-link :to="{ name: 'products', query: { type: 'grid' } }"
                ><span :class="{ active: productView == 'grid' }"><i class="icon-icons"></i></span
              ></router-link>
              <router-link :to="{ name: 'products', query: { type: 'list' } }"
                ><span :class="{ active: productView == 'list' }"><i class="icon-list4"></i></span
              ></router-link>
            </div>
          </div>

          <div class="viewtype__select" v-if="$isMobile()">
            <div class="type">
              <a href="javascript:void(0)" @click.stop="assignState({key:'mobileCategoryMenuState',value:true})">
                <span><i class="icon-icons"></i></span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="result__filter ps-mobile-result">
        <h5>Your filters:</h5>
        <ul>
          <li v-if="filters.min_price" @click="filters.min_price = ''">Min: {{getCurrencyCode }} {{ filters.min_price }}<i class="icon-cross"></i></li>
          <li v-if="filters.max_price" @click="filters.max_price = ''">Max: {{getCurrencyCode }} {{ filters.max_price }}<i class="icon-cross"></i></li>
        </ul>
      </div>

      <div class="result__sort">
        <div class="viewtype--block">
          <div class="viewtype__sortby">
            <div class="select">
              <select class="single-select2-no-search" v-model="sort_by">
                <option value="" selected="selected">
                  Sort by
                </option>
                <option value="name-asc">Sort by Name (A-Z)</option>
                <option value="name-desc">Sort by Name (Z-A)</option>
                <option value="price-asc">Sort by Price - Low to High</option>
                <option value="price-desc">Sort by Price - High to Low</option>
              </select>
            </div>
          </div>

          <div class="viewtype__select">
            <span class="text">View: </span>
            <div class="select">
              <select class="single-select2-no-search" name="state" v-model="pageFilter.limit">
                <option value="60">60 per page</option>
                <option value="36">36 per page</option>
                <option value="24">24 per page</option>
                <option value="12">12 per page</option>
              </select>
            </div>

            <div class="type">
              <router-link :to="{ name: 'products', query: { type: 'grid' } }"
                ><span :class="{ active: productView == 'grid' }"><i class="icon-icons"></i></span
              ></router-link>
              <router-link :to="{ name: 'products', query: { type: 'list' } }"
                ><span :class="{ active: productView == 'list' }"><i class="icon-list4"></i></span
              ></router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="result__header mobile">
        <h4 class="title">{{ meta.total }}<span>Product Found</span></h4>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    productView: {
      type: String,
      default: "grid",
    },
  },
  data() {
    return {
      pageFilter: {
        limit: 12,
      },
      sort_by: "",
    };
  },
  watch: {
    "pageFilter.limit": {
      handler() {
        this.$emit("fetchRequest", this.pageFilter);
      },
    },
    "filters.min_price": {
      handler() {
        this.$emit("fetchRequest", this.pageFilter);
      },
    },

     "filters.max_price": {
      handler() {
        this.$emit("fetchRequest", this.pageFilter);
      },
    },

    sort_by() {
      if (this.pageFilter.sort !== undefined) delete this.pageFilter["sort"];
      if (this.pageFilter.order !== undefined) delete this.pageFilter["order"];

      var sortBy = this.sort_by.split("-");
      if (sortBy.length >= 2) {
        this.pageFilter.sort = sortBy[0];
        this.pageFilter.order = sortBy[1];
      }

      this.$emit("fetchRequest", this.pageFilter);
    },
  },
  computed: {
    ...mapGetters("product", ["meta", "filters", 'getCurrencyCode']),
  },
  methods: {
    ...mapActions('home',['assignState']),
  },
};
</script>

<style lang="scss" scoped>
</style>
