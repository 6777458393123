<template>
  <main class="no-main">
    <div class="ps-breadcrumb">
      <div class="container-fluid-sy">
        <ul class="ps-breadcrumb__list">
          <li class="active">
            <router-link :to="{name: 'home'}">Home</router-link>
          </li>
          <li><a href="javascript:void(0);">Products</a></li>
        </ul>
      </div>
    </div>

    <section class="section-shop">
      <div class="container-fluid-sy">
        <div class="shop__content">
          <div class="row">
            <!-- AllCategories Section -->
            <AllCategories @fetchRequest="onFetchRequest"/>

            <div class="col-12 col-lg-9">
              <!-- ProductFillter Section -->
              <ProductFillter :productView="productView" @fetchRequest="onFetchRequest"/>

              <!-- Products Section -->
              <ProductsView :productView="productView" @fetchRequest="onFetchRequest"/>
            </div>
          </div>
        </div>
      </div>
    </section>
    
    <!-- QuickViewModal Section -->
    <QuickViewModal />

    <!-- AddToCartModal Section -->
    <AddToCartModal />
  </main>
</template>


<script>
import AllCategories from "./Components/AllCategories";
import ProductFillter from "./Components/ProductFillter";
import ProductsView from "./Components/ProductsView";
import QuickViewModal from "./Components/QuickViewModal";
import AddToCartModal from "./Components/AddToCartModal";

import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      productView: "grid",
      page:1
    };
  },

  components: {
    AllCategories,
    ProductFillter,
    ProductsView,
    QuickViewModal,
    AddToCartModal,
  },

  watch: {
    $route() {
        var query = this.$route.query;
        if('type' in query) {
            this.productView = query.type;
        }

      this.onFetchRequest(this.$urlParams());
      this.handleInfinite(this.$urlParams()); 
    },
  },
  mounted() {
    var query = this.$route.query;
    if('type' in query) {
        this.productView = query.type;
    }
    
    this.onFetchRequest(this.$urlParams());
    this.handleInfinite(this.$urlParams()); 
  },
  computed:{
    ...mapGetters('product',['filters','productsLoaded', 'currentPage', 'fetchingProducts', 'productsComplete'])
  },
  methods: {
    ...mapActions('product',['fetchProducts','fetchAndAppendProducts','assignState']),
    
    onFetchRequest(filters){

      let mergedFilter = {...this.filters,...filters};

      for (var propName in mergedFilter) {
        if (mergedFilter[propName] === null || mergedFilter[propName] ===  '' || mergedFilter[propName] === undefined) {
          delete mergedFilter[propName];
        }
      }      
      this.assignState({key:'filters',value:mergedFilter});
      delete mergedFilter.price;
      delete mergedFilter.page;
      window.history.pushState('',location.href+'?'+this.$pushLocation(mergedFilter))

      this.fetchProducts();
    },
    handleInfinite() {
      window.onscroll = () => {
        let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
        
        if (bottomOfWindow && !this.fetchingProducts && !this.productsComplete) {
          // console.log(this.currentPage)
          this.fetchAndAppendProducts().then(() => {
            // console.log(this.currentPage)
          })
        }
      }
    }
  },
};
</script>
