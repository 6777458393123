<template>
  <div class="col-12 col-lg-3">
    <div class="ps-shop--sidebar">
      <div class="sidebar__sort">
        
        <div class="sidebar__block open">
          <div class="sidebar__title">
           Categories <span class="shop-widget-toggle"><i class="icon-minus"></i></span>
          </div>
          <div class="block__content" style="overflow:scroll">
            <ul class="category-tree">
              <li v-for="(category, index) in mainCategories" :key="index">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" :id="`life-${category.id}`" :value="category.id" v-model="filters.category_id"  @change="filter"/>
                  <label :for="`life-${category.id}`" class="main">{{category.name}}</label>
                  <template v-if="category.childs.length">
                      <div style="margin-left:1rem" class="child" v-for="(child, index) in category.childs" :key="index">
                        <input class="form-check-input" type="checkbox" :id="`life-${child.id}`" :value="child.id" v-model="filters.category_id"  @change="filter"/>
                        <label :for="`life-${child.id}`">{{child.name}}</label>
                      </div>
                  </template>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="sidebar__block open">
          <div class="sidebar__title">
            BY PRICE<span class="shop-widget-toggle"><i class="icon-minus"></i></span>
          </div>
          <div class="block__content">
            <div class="block__input" style="padding-top:10px">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">{{getCurrencyCode}}</span>
                </div>
                <input class="form-control" type="text" id="input-with-keypress-0" v-model="filters.min_price" />
              </div>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">{{getCurrencyCode}}</span>
                </div>
                <input class="form-control" type="text" id="input-with-keypress-1" v-model="filters.max_price" />
              </div>
              <button @click="filter">Go</button>
            </div>
          </div>
        </div>
        
        
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
  export default {
    data() {
      return {
        filters: {
          min_price: '',
          max_price: '',
          category_id:[]
        },
      };
    },
    computed:{
      ...mapGetters('categories',['categories_subcategories','mainCategories']),
      ...mapGetters('product',['getCurrencyCode'])
    },
    methods: {
      filter() {
        this.$emit("fetchRequest", this.filters);
      },
    },
  };
</script>
<style>
  .category-tree .main{
    font-weight: bold;
  }
  .category-tree .child label {
      font-size: 13px;
      margin-left: 1rem;
     
  }
</style>
