<template>
  <div
    class="modal fade"
    id="popupAddToCart"
    data-backdrop="static"
    data-keyboard="false"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-xl ps-addcart">
      <div class="modal-content">
        <div class="modal-header">
          <button
            class="close"
            type="button"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="container-fluid">
            <div class="alert__success">
              <i class="icon-checkmark-circle"></i> "Morrisons The Best Beef
              Topside" successfully added to you cart.
              <a href="shopping-cart.html">View cart(3)</a>
            </div>
            <hr />
            <h3 class="cart__title">CUSTOMERS WHO BOUGHT THIS ALSO BOUGHT:</h3>
            <div class="cart__content">
              <div
                class="owl-carousel"
                data-owl-auto="true"
                data-owl-loop="true"
                data-owl-speed="5000"
                data-owl-gap="0"
                data-owl-nav="false"
                data-owl-dots="true"
                data-owl-item="4"
                data-owl-item-xs="2"
                data-owl-item-sm="2"
                data-owl-item-md="2"
                data-owl-item-lg="4"
                data-owl-item-xl="4"
                data-owl-duration="1000"
                data-owl-mousedrag="on"
              >
                <div class="cart-item">
                  <div class="ps-product--standard">
                    <a href="product-default.html"
                      ><img
                        class="ps-product__thumbnail"
                        src="img/products/01-Fresh/01_35a.jpg"
                        alt="alt"
                    /></a>
                    <div class="ps-product__content">
                      <p class="ps-product__type">
                        <i class="icon-store"></i>Farmart
                      </p>
                      <a href="product-default.html">
                        <h5 class="ps-product__name">
                          Extreme Budweiser Light Can
                        </h5>
                      </a>
                      <p class="ps-product__unit">500g</p>
                      <div class="ps-product__rating">
                        <select class="rating-stars">
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4" selected="selected">4</option>
                          <option value="5">5</option></select
                        ><span>(4)</span>
                      </div>
                      <p class="ps-product-price-block">
                        <span class="ps-product__sale">$8.90</span
                        ><span class="ps-product__price">$9.90</span
                        ><span class="ps-product__off">15% Off</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="cart-item">
                  <div class="ps-product--standard">
                    <a href="product-default.html"
                      ><img
                        class="ps-product__thumbnail"
                        src="img/products/01-Fresh/01_16a.jpg"
                        alt="alt"
                    /></a>
                    <div class="ps-product__content">
                      <p class="ps-product__type">
                        <i class="icon-store"></i>Karery Store
                      </p>
                      <a href="product-default.html">
                        <h5 class="ps-product__name">
                          Honest Organic Still Lemonade
                        </h5>
                      </a>
                      <p class="ps-product__unit">100g</p>
                      <div class="ps-product__rating">
                        <select class="rating-stars">
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5" selected="selected">
                            5
                          </option></select
                        ><span>(14)</span>
                      </div>
                      <p class="ps-product-price-block">
                        <span class="ps-product__price-default">$1.99</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="cart-item">
                  <div class="ps-product--standard">
                    <a href="product-default.html"
                      ><img
                        class="ps-product__thumbnail"
                        src="img/products/01-Fresh/01_12a.jpg"
                        alt="alt"
                    /></a>
                    <div class="ps-product__content">
                      <p class="ps-product__type">
                        <i class="icon-store"></i>John Farm
                      </p>
                      <a href="product-default.html">
                        <h5 class="ps-product__name">Natures Own 100% Wheat</h5>
                      </a>
                      <p class="ps-product__unit">100g</p>
                      <div class="ps-product__rating">
                        <select class="rating-stars">
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option></select
                        ><span>(0)</span>
                      </div>
                      <p class="ps-product-price-block">
                        <span class="ps-product__price-default">$4.49</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="cart-item">
                  <div class="ps-product--standard">
                    <a href="product-default.html"
                      ><img
                        class="ps-product__thumbnail"
                        src="img/products/01-Fresh/01_15a.jpg"
                        alt="alt"
                    /></a>
                    <div class="ps-product__content">
                      <p class="ps-product__type">
                        <i class="icon-store"></i>Farmart
                      </p>
                      <a href="product-default.html">
                        <h5 class="ps-product__name">Avocado, Hass Large</h5>
                      </a>
                      <p class="ps-product__unit">300g</p>
                      <div class="ps-product__rating">
                        <select class="rating-stars">
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3" selected="selected">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option></select
                        ><span>(6)</span>
                      </div>
                      <p class="ps-product-price-block">
                        <span class="ps-product__sale">$6.99</span
                        ><span class="ps-product__price">$9.90</span
                        ><span class="ps-product__off">25% Off</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="cart-item">
                  <div class="ps-product--standard">
                    <a href="product-default.html"
                      ><img
                        class="ps-product__thumbnail"
                        src="img/products/06-SoftDrinks-TeaCoffee/06_3a.jpg"
                        alt="alt"
                    /></a>
                    <div class="ps-product__content">
                      <p class="ps-product__type">
                        <i class="icon-store"></i>Sun Farm
                      </p>
                      <a href="product-default.html">
                        <h5 class="ps-product__name">Kevita Kom Ginger</h5>
                      </a>
                      <p class="ps-product__unit">200g</p>
                      <div class="ps-product__rating">
                        <select class="rating-stars">
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4" selected="selected">4</option>
                          <option value="5">5</option></select
                        ><span>(6)</span>
                      </div>
                      <p class="ps-product-price-block">
                        <span class="ps-product__sale">$4.90</span
                        ><span class="ps-product__price">$3.99</span
                        ><span class="ps-product__off">15% Off</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>