<template>
  <div class="result__content">
    <ProductGridView v-show="productView=='grid'" />
    <ProductListView v-show="productView=='list'" />

    <div class="ps-pagination blog--pagination" v-show="!isLoading && products.length" v-if="false">
      <paginate
        :forcePage="meta.current_page"
        :page-count="meta.last_page"
        :firstLastButton="false"
        :click-handler="clickOnPagination"
        prev-text="<i class='icon-chevron-left'></i>"
        next-text="<i class='icon-chevron-right'></i>"
        :container-class="'pagination'">
      </paginate>
    </div>
  </div>
</template>

<script>
import ProductGridView from "./ProductGridView";
import ProductListView from "./ProductListView";

import Paginate from '@/plugins/paginate'
import { mapGetters } from 'vuex';

export default {
  props: {
    productView: {
      type: String,
      default: "grid",
    },
  },

  components: {
    ProductGridView,
    ProductListView,
    Paginate
  },
  computed:{
    ...mapGetters('product',['meta','isLoading','products'])
  },
  methods: {
    clickOnPagination(p){
      this.$emit('fetchRequest',{page:p})
    }
  },
};
</script>